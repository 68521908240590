// assets
import { IconKey, IconBox, IconNotebook, IconUsers, IconReceipt2, IconNotes } from '@tabler/icons';

// constant
const icons = {
    IconKey,
    IconBox,
    IconNotebook,
    IconUsers,
    IconReceipt2,
    IconNotes
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Pages',
    caption: 'Pages Caption',
    type: 'group',
    children: [
        {
            id: 'products',
            title: 'Products',
            type: 'collapse',
            icon: icons.IconBox,

            children: [
                {
                    id: 'addProduct',
                    title: 'Add Product',
                    type: 'item',
                    url: '/products/add-products',
                    breadcrumbs: false
                },
                {
                    id: 'searchProduct',
                    title: 'Search Product',
                    type: 'item',
                    url: '/products/search-products',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'categories',
            title: 'Category',
            type: 'collapse',
            icon: icons.IconNotebook,

            children: [
                {
                    id: 'searchCategory',
                    title: 'Search Categories',
                    type: 'item',
                    url: '/categories/search-categories',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'ordrers',
            title: 'Orders',
            type: 'collapse',
            icon: icons.IconReceipt2,

            children: [
                {
                    id: 'searchOrders',
                    title: 'Search Orders',
                    type: 'item',
                    url: '/orders/search-orders',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.IconUsers,

            children: [
                {
                    id: 'searchUsers',
                    title: 'Search Users',
                    type: 'item',
                    url: '/users/search-users',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'customers',
            title: 'Customers',
            type: 'item',
            icon: icons.IconUsers,
            breadcrumbs: false,
            url: '/customers/search-customers'
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'item',
            icon: icons.IconNotes,
            breadcrumbs: false,
            url: '/transactions'
        }
    ]
};

export default pages;
