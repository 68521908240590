// material-ui
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MainCard from 'ui-component/cards/MainCard';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, ButtonBase, Chip, Dialog, Grid, IconButton, InputAdornment, OutlinedInput, Slide, TablePagination } from '@mui/material';
import { IconAdjustmentsHorizontal, IconSearch } from '@tabler/icons';
import { useTheme, styled } from '@mui/material/styles';
import { useState } from 'react';
import { Box, shouldForwardProp } from '@mui/system';
import { forwardRef } from 'react';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import { useEffect } from 'react';
import boxImage from 'assets/images/box.jpg';
import ProductImage from 'ui-component/product-image/ProductImage';
import ConfirmationDialog from 'ui-component/confirmation-dialog/ConfirmationDialog';
import ViewUserPage from '../view-users/ViewUsers';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LottieLoader from 'ui-component/LottieLoader';

// ==============================|| Search User PAGE ||============================== //

const theme = createTheme({
    palette: {
        primary: {
            main: '#1b5e20'
        },
        secondary: {
            main: '#e65100'
        }
    }
});

function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const rows = [
//     createData('T1234', 'Frozen yoghurt', 159, 6.0),
//     createData('T134', 'Ice cream sandwich', 237, 9.0),
//     createData('T234', 'Eclair', 262, 16.0),
//     createData('T123', 'Cupcake', 305, 3.7),
//     createData('T14', 'Gingerbread', 356, 16.0)
// ];

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

const imgBaseUrl = 'https://starfish-app-vil5p.ondigitalocean.app/uploads/';

export default function SearchUserPage() {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [value, setValue] = useState('');
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [deletingId, setDeleteingId] = useState();
    const [users, setUsers] = useState([]);
    const [rows, setRows] = useState([]);
    const [isDataLoading, setDataLoading] = useState(true);

    const columns = [
        { field: 'id', headerName: 'User ID', minWidth: 100, flex: 1 },
        {
            field: 'firstName',
            headerName: 'First name',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'phone',
            headerName: 'Contact No',
            // type: 'number',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'email',
            headerName: 'Email',
            sortable: true,
            minWidth: 100,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            renderCell: (params) => getStatusChip(params.value),
            minWidth: 100,
            flex: 1
        }
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleClickConfirmClose = () => {
        setOpenConfirm(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (event, rowData) => {
        console.log(event);
        handleClickOpen();
        setSelectedRow(event.row.allData);
    };

    function getStatusChip(status) {
        console.log(status);
        switch (status) {
            case 'active':
                return <Chip label="Active" color="success" />;
            case 'pending':
                return <Chip label="Pending" color="primary" />;
            case 'suspend':
                return <Chip label="Suspend" color="error" />;
            default:
                return <Chip label="Unknown" />;
        }
    }

    function deleteUsers(id) {
        HttpCommon.delete('/api/user', {
            data: {
                id: id
            }
        })
            .then((res) => {
                console.log(res.data);
                getUsers();
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getUsers() {
        HttpCommon.get('/api/user')
            .then((res) => {
                console.log(res.data);
                setUsers(res.data.data);
                const tempArr = [];
                res.data.data.map((element) => {
                    tempArr.push({
                        id: element.userId,
                        firstName: element.firstName,
                        lastName: element.lastName,
                        phone: element.phone,
                        email: element.email,
                        status: element.status,
                        allData: element
                    });
                });
                setRows(tempArr);
                setDataLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setDataLoading(false);
            });
    }

    function getUsersById() {
        setDataLoading(true);
        console.log('id = ' + id);
        if (id !== undefined && id != '') {
            HttpCommon.get(`/api/user/${id}`)
                .then((res) => {
                    console.log(res.data);
                    setUsers(res.data.data);
                    const tempArr = [];
                    res.data.data.map((element) => {
                        tempArr.push({
                            id: element.userId,
                            firstName: element.firstName,
                            lastName: element.lastName,
                            phone: element.phone,
                            email: element.email,
                            status: element.status,
                            allData: element
                        });
                    });
                    setRows(tempArr);
                    setDataLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setDataLoading(false);
                });
        } else {
            getUsers();
        }
    }

    function getUsersByTitle() {
        setDataLoading(true);
        console.log('title = ' + title);
        if (title !== undefined && title !== '') {
            HttpCommon.get(`/api/user/email/${title}`)
                .then((res) => {
                    console.log(res.data);
                    setUsers(res.data.data);
                    const tempArr = [];
                    res.data.data.map((element) => {
                        tempArr.push({
                            id: element.userId,
                            firstName: element.firstName,
                            lastName: element.lastName,
                            phone: element.phone,
                            email: element.email,
                            status: element.status,
                            allData: element
                        });
                    });
                    setRows(tempArr);
                    setDataLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setDataLoading(false);
                });
        } else {
            getUsers();
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <>
            {isDataLoading ? (
                <LottieLoader />
            ) : (
                <MainCard title="Search Users">
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                            <OutlinedInput
                                sx={{ width: '100%', pr: 1, pl: 2, my: 0 }}
                                id="input-search-profile"
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        getUsersById();
                                    }
                                }}
                                placeholder="Search by ID"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ButtonBase sx={{ buserRadius: '12px' }}>
                                            <HeaderAvatarStyle variant="rounded">
                                                <IconSearch stroke={1.5} size="1.3rem" onClick={getUsersById} />
                                            </HeaderAvatarStyle>
                                        </ButtonBase>
                                    </InputAdornment>
                                }
                                aria-describedby="search-helper-text"
                                inputProps={{
                                    'aria-label': 'weight'
                                }}
                            />
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                            <OutlinedInput
                                sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                                id="input-search-profile"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        getUsersByTitle();
                                    }
                                }}
                                placeholder="Search by Email"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ButtonBase sx={{ buserRadius: '12px' }}>
                                            <HeaderAvatarStyle variant="rounded">
                                                <IconSearch stroke={1.5} size="1.3rem" onClick={getUsersByTitle} />
                                            </HeaderAvatarStyle>
                                        </ButtonBase>
                                    </InputAdornment>
                                }
                                aria-describedby="search-helper-text"
                                inputProps={{
                                    'aria-label': 'weight'
                                }}
                            />
                        </Grid>
                    </Grid>
                    <TableContainer elevation={2} component={Paper}>
                        <Box sx={{ height: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ThemeProvider theme={theme}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10
                                            }
                                        }
                                    }}
                                    pageSizeOptions={[5]}
                                    // checkboxSelection
                                    disableRowSelectionOnClick
                                    // isRowSelectable={false}
                                    onRowClick={handleRowClick}
                                />
                            </ThemeProvider>
                        </Box>
                    </TableContainer>

                    {/* <TableContainer elevation={2} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">FirstName</TableCell>
                            <TableCell align="left">Contact No</TableCell>
                            <TableCell align="right">Total Amount</TableCell>
                            <TableCell align="right">User Date</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow
                                hover
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { buser: 0 } }}
                                onClick={() => {
                                    handleClickOpen();
                                    setSelectedRow(row);
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="left">{row.firstName}</TableCell>
                                <TableCell align="left">{row.phone}</TableCell>
                                <TableCell align="right">{row.userAmount}</TableCell>
                                <TableCell align="right">{row.useredDate.substring(0, 10)}</TableCell>
                                <TableCell align="center">{getStatusChip(row.status)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
                    <Dialog fullScreen open={open} onClose={handleClickOpen} TransitionComponent={Transition}>
                        <ViewUserPage open={open} setOpen={setOpen} rowDetails={selectedRow} onEnd={getUsers} />
                    </Dialog>
                    <Dialog
                        scroll="paper"
                        fullWidth
                        maxWidth="sm"
                        open={openConfirm}
                        onClose={handleClickConfirmClose}
                        TransitionComponent={Transition}
                    >
                        <ConfirmationDialog
                            setOpen={setOpenConfirm}
                            Title="Are you sure ?"
                            Description="You are trying to delete a user. Are you want to delete this?"
                            onAgree={() => deleteUsers(deletingId)}
                        />
                    </Dialog>
                </MainCard>
            )}
        </>
    );
}
