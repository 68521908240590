import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AddProductPage from 'views/pages/products/add-products/AddProducts';
import SearchProductPage from 'views/pages/products/search-products/SearchProducts';
import EditProductPage from 'views/pages/products/edit-products/EditProducts';
import AddCategoriesPage from 'views/pages/categories/add-categories/AddCategories';
import SearchCategoriesPage from 'views/pages/categories/search-categories/SearchCategories';
import SearchOrderPage from 'views/pages/orders/search-orders/SearchOrders';
import SearchUserPage from 'views/pages/users/search-users/SearchUsers';
import SearchCustomerPage from 'views/pages/customers/search-customer/SearchCustomers';
import TransactionsPage from 'views/pages/transactions/Transactions';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'products',
            children: [
                {
                    path: 'add-products',
                    element: <AddProductPage />
                },
                {
                    path: 'search-products',
                    element: <SearchProductPage />
                }
            ]
        },
        {
            path: 'categories',
            children: [
                {
                    path: 'search-categories',
                    element: <SearchCategoriesPage />
                }
            ]
        },
        {
            path: 'orders',
            children: [
                {
                    path: 'search-orders',
                    element: <SearchOrderPage />
                }
            ]
        },
        {
            path: 'users',
            children: [
                {
                    path: 'search-users',
                    element: <SearchUserPage />
                }
            ]
        },
        {
            path: 'customers',
            children: [
                {
                    path: 'search-customers',
                    element: <SearchCustomerPage />
                }
            ]
        },
        {
            path: 'transactions',
            element: <TransactionsPage />
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
