// material-ui
import {
    AppBar,
    Autocomplete,
    Button,
    Chip,
    Dialog,
    Divider,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper,
    Slide,
    Step,
    StepLabel,
    Stepper,
    TableContainer,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Box, ThemeProvider } from '@mui/system';
import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { shouldForwardProp } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import Dropzone from 'react-dropzone-uploader';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Check } from '@mui/icons-material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CatogorySelector from 'ui-component/category-selector/CategorySelector';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import ProductImage from 'ui-component/product-image/ProductImage';
import boxImage from 'assets/images/box.jpg';
import ConfirmationDialog from 'ui-component/confirmation-dialog/ConfirmationDialog';
import { DataGrid } from '@mui/x-data-grid';

// ==============================|| View User PAGE ||============================== //

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledButton = styled(Button, { shouldForwardProp })(({ theme }) => ({
    // cursor: 'pointer',
    // padding: 16,
    '&:hover': {
        // background: theme.palette.primary
        backgroundColor: '#fff',
        color: '#3c52b2'
    }
}));

// specify upload params and url for your files
const getUploadParams = ({ meta }) => {
    return { url: ' ' };
};

const imgBaseUrl = 'https://starfish-app-vil5p.ondigitalocean.app/uploads/';

const userStatusTags = ['Active', 'Pending', 'Suspend'];

export default function ViewUserPage({ open, setOpen, rowDetails, onEnd }) {
    const theme = useTheme();
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [stepType, setStepType] = useState('next');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [details, setDetails] = useState(undefined);
    const [payoutAmount, setPayoutAmount] = useState('');
    const [accBalance, setAccBalance] = useState({
        income: 0,
        checkout: 0,
        total: 0
    });
    const [values, setValues] = useState({
        branchName: '',
        accountNumber: '',
        branchName: ''
    });

    const [rows, setRows] = useState([]);

    const columns = [
        { field: 'id', headerName: 'Transcation ID', minWidth: 150, flex: 1.5 },
        {
            field: 'type',
            headerName: 'Type',
            minWidth: 100,
            renderCell: (params) => getStatusChip(params.value),
            flex: 1,
            editable: false
        },
        {
            field: 'amount',
            amount: 'Amount',
            type: 'number',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'orderId',
            headerName: 'Order ID',
            sortable: true,
            minWidth: 150,
            flex: 1.5
        },
        {
            field: 'date',
            headerName: 'Date',
            sortable: true,
            minWidth: 100,
            flex: 1
        }
    ];

    const handleChange = (prop) => (event) => {
        console.log(event.target.value);
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickClose = () => {
        setCategoryOpen(false);
    };

    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleClickConfirmClose = () => {
        handleStep();
        setOpenConfirm(false);
    };

    const handleClose = () => {
        onEnd();
        setOpen(false);
    };

    const handleSave = () => {
        if (values.status && values.status !== '') {
            handleUpdateUserStatus();
        } else {
            messages.addMessage({ title: 'Error!', msg: 'Enter valid user status', type: 'danger' });
        }
    };

    const handleUpdateBankInfo = () => {
        // console.log(files.map((f) => f.meta));
        const data = {
            userId: details.userId,
            nameOfBank: values.nameOfBank,
            branchName: values.branchName,
            accountNumber: values.accountNumber
        };

        HttpCommon.put(`/api/user`, data)
            .then((response) => {
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Updated!', msg: 'User Bank Details Update Succesfully', type: 'success' });
                }
                details.nameOfBank = values.nameOfBank;
                details.branchName = values.branchName;
                details.accountNumber = values.accountNumber;
                setDetails(details);
                onEnd();
                setIsEditable(false);
                // setOpen(false);
            })
            .catch((err) => {
                console.log(err);
                // setOpen(false);
            });
    };

    const handleUpdateUserStatus = () => {
        const key = localStorage.getItem('userID');
        const data = {
            userId: details.userId,
            status: values.status
        };
        console.log(data);
        HttpCommon.put(`/api/user/status`, data)
            .then((response) => {
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Updated!', msg: 'User Saved Succesfully', type: 'success' });
                    onEnd();
                    setOpen(false);
                } else {
                    messages.addMessage({ title: 'Error!', msg: 'User Save Failed', type: 'danger' });
                }
            })
            .catch((err) => {
                console.log(err);
                messages.addMessage({ title: 'Error!', msg: 'User Save Failed', type: 'danger' });
            });
    };

    function getStatusChip(status) {
        console.log(status);
        switch (status) {
            case 'income':
                return <Chip label="Income" color="success" />;
            case 'checkout':
            case 'checkOut':
                return <Chip label="Checkout" color="secondary" />;
            default:
                return <Chip label="Unknown" />;
        }
    }

    function getTranscations() {
        let date = new Date().toJSON();
        const data = {
            userId: rowDetails.userId,
            startDate: `2020-01-01T00:00:00.000+00:00`,
            endDate: date
        };
        HttpCommon.post('/api/transaction/getAllTransactionsByUserIdAndDateRange', data)
            .then((res) => {
                console.log(res.data);
                const tempArr = [];
                res.data.data.map((element) => {
                    if (element.type === 'income') {
                        tempArr.push({
                            id: element.id,
                            type: element.type,
                            amount: element.amount,
                            orderId: element.orderId.id,
                            date: element.createdAt.substring(0, 10),
                            allData: element
                        });
                    } else {
                        tempArr.push({
                            id: element.id,
                            type: element.type,
                            amount: element.amount,
                            orderId: '',
                            date: element.createdAt.substring(0, 10),
                            allData: element
                        });
                    }
                });
                setRows(tempArr);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getTranscationsGrouped() {
        let date = new Date().toJSON();
        const data = {
            userId: rowDetails.userId,
            startDate: `2020-01-01T00:00:00.000+00:00`,
            endDate: date
        };
        HttpCommon.post('/api/transaction/getAllTransactionsByUserIdAndDateRangeAndGrouped', data)
            .then((res) => {
                console.log(res.data);
                setAccBalance(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function handlePayout() {
        const data = {
            userId: rowDetails.userId,
            amount: payoutAmount
        };
        if (!payoutAmount || payoutAmount < 1) {
            messages.addMessage({ title: 'Error!', msg: 'Enter Valid Amount', type: 'danger' });
            return;
        }
        HttpCommon.post('/api/transaction/checkout', data)
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    messages.addMessage({ title: 'Payout Success!', msg: 'Payout Process Successfully Done', type: 'success' });
                    getTranscations();
                    getTranscationsGrouped();
                } else {
                    messages.addMessage({ title: 'Payout Failed!', msg: res.data.message, type: 'danger' });
                }
                setPayoutAmount('');
            })
            .catch((err) => {
                console.log(err);
                messages.addMessage({ title: 'Error!', msg: 'Payout Failed!', type: 'danger' });
            });
    }

    useEffect(() => {
        console.log(rowDetails);
        console.log(rowDetails.status);
        getTranscations();
        getTranscationsGrouped();
        if (rowDetails !== undefined) {
            setValues({
                branchName: rowDetails.branchName,
                nameOfBank: rowDetails.nameOfBank,
                accountNumber: rowDetails.accountNumber
            });
            //setValues({ ...values, status: rowDetails.status });
            setDetails(rowDetails);
            // handleInitStatus(rowDetails.status);
        }
    }, []);

    return (
        details !== undefined && (
            <>
                <AppBar sx={{ position: 'relative' }} color="secondary">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="subtitle1" component="div">
                            View User
                        </Typography>
                        <StyledButton color="inherit" onClick={handleSave}>
                            Save
                        </StyledButton>
                    </Toolbar>
                </AppBar>
                <Grid container justifyContent="flex-start" padding={3} spacing={2}>
                    <Grid container xs={12} lg={8} pt={2} pl={2} spacing={0}>
                        <Grid item justifyContent="flex-start" alignItems="flex-start" xs={12} lg={12} mb={2}>
                            <MainCard elevation={2} title="Payout Process" boxShadow shadow="0 2px 5px 2px rgba(0,0,0,0.15)">
                                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                    <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                                        <TextField
                                            id="outlined-error"
                                            fullWidth
                                            label="Amount"
                                            value={payoutAmount}
                                            onChange={(event) => setPayoutAmount(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" xs={12} md={4}>
                                        <Box sx={{ mb: 1 }}>
                                            <AnimateButton>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => handlePayout()}
                                                >
                                                    Payout
                                                </Button>
                                            </AnimateButton>
                                        </Box>
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" xs={12} md={12} mb={2} />
                                </Grid>
                            </MainCard>
                        </Grid>
                        <Grid item xs={12} lg={12} mb={2}>
                            {/* <MainCard elevation={2} title="Transcation Details" boxShadow shadow="0 2px 5px 2px rgba(0,0,0,0.15)"> */}
                            <TableContainer elevation={2} component={Paper}>
                                <Box sx={{ height: 850, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <ThemeProvider theme={theme}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 15
                                                    }
                                                }
                                            }}
                                            pageSizeOptions={[5]}
                                            // checkboxSelection
                                            disableRowSelectionOnClick
                                        />
                                    </ThemeProvider>
                                </Box>
                            </TableContainer>
                            {/* </MainCard> */}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-around" alignItems="unset" xs={12} lg={4} pt={2} pl={2} spacing={0}>
                        <Grid item justifyContent="flex-start" alignItems="start" xs={12} lg={12} mb={2}>
                            <MainCard elevation={2} title="Account Balance" boxShadow shadow="0 2px 5px 2px rgba(0,0,0,0.15)">
                                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                    <Grid item justifyContent="center" alignItems="center" xs={6}>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Total Income
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Total Checkout
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Total Balance
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" xs={6}>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {accBalance.income}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {accBalance.checkout}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {accBalance.total}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                        <Grid item justifyContent="flex-start" alignItems="initial" xs={12} lg={12} mb={2}>
                            <MainCard elevation={2} title="User" boxShadow shadow="0 2px 5px 2px rgba(0,0,0,0.15)">
                                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                    <Grid item justifyContent="center" alignItems="center" xs={6}>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            User ID
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            First Name
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Last Name
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Role
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Refferrer ID
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={2} mt={3}>
                                            Status
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" xs={6}>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.userId}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.firstName}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.lastName}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.role}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.refferrerID}
                                        </Typography>
                                        <Autocomplete
                                            id="tags-filled"
                                            options={userStatusTags}
                                            defaultValue={details.status.charAt(0).toUpperCase() + details.status.slice(1)}
                                            onChange={(event, newValue) => {
                                                console.log(newValue.toLowerCase());
                                                setValues({ ...values, status: newValue.toLowerCase() });
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip variant="filled" label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                                        />
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                        <Grid item justifyContent="flex-start" alignItems="flex-start" xs={12} lg={12} mb={2}>
                            <MainCard
                                elevation={2}
                                title="Bank Details"
                                boxShadow
                                shadow="0 2px 5px 2px rgba(0,0,0,0.15)"
                                secondary={
                                    !isEditable ? (
                                        <AnimateButton>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => setIsEditable(true)}
                                                sx={{ borderRadius: 20 }}
                                            >
                                                Edit
                                            </Button>
                                        </AnimateButton>
                                    ) : (
                                        <div style={{ display: 'flex' }}>
                                            <AnimateButton>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    color="secondary"
                                                    onClick={() => setIsEditable(false)}
                                                    sx={{ borderRadius: 20 }}
                                                >
                                                    Close
                                                </Button>
                                            </AnimateButton>
                                            <div style={{ width: 20 }} />
                                            <AnimateButton>
                                                <Button
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleUpdateBankInfo}
                                                    sx={{ borderRadius: 20 }}
                                                >
                                                    Save
                                                </Button>
                                            </AnimateButton>
                                        </div>
                                    )
                                }
                            >
                                {isEditable ? (
                                    <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                        <Grid item justifyContent="center" alignItems="center" xs={12}>
                                            <TextField
                                                id="outlined-error"
                                                fullWidth
                                                label="Name of bank"
                                                value={values.nameOfBank}
                                                onChange={handleChange('nameOfBank')}
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" xs={12}>
                                            <TextField
                                                id="outlined-error"
                                                fullWidth
                                                label="branch Name"
                                                value={values.branchName}
                                                onChange={handleChange('branchName')}
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" xs={12}>
                                            <TextField
                                                id="outlined-error"
                                                fullWidth
                                                label="Account No"
                                                value={values.accountNumber}
                                                onChange={handleChange('accountNumber')}
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                        <Grid item justifyContent="center" alignItems="center" xs={6}>
                                            <Typography variant="h6" fontSize={14} mb={1}>
                                                Bank Name
                                            </Typography>
                                            <Typography variant="h6" fontSize={14} mb={1}>
                                                Branch Name
                                            </Typography>
                                            <Typography variant="h6" fontSize={14} mb={1}>
                                                Account No
                                            </Typography>
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" xs={6}>
                                            <Typography variant="subtitle2" fontSize={14} mb={1}>
                                                {details.nameOfBank}
                                            </Typography>
                                            <Typography variant="subtitle2" fontSize={14} mb={1}>
                                                {details.branchName}
                                            </Typography>
                                            <Typography variant="subtitle2" fontSize={14} mb={1}>
                                                {details.accountNumber}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </MainCard>
                        </Grid>
                        <Grid item justifyContent="center" alignItems="center" xs={12} lg={12} mb={2}>
                            <MainCard elevation={2} title="Contact Details" boxShadow shadow="0 2px 5px 2px rgba(0,0,0,0.15)">
                                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                    <Grid item justifyContent="center" alignItems="center" xs={6}>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Address
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14}>
                                            {details.address}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14}>
                                            {details.landmark}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14}>
                                            {details.city}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14}>
                                            {details.province}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.postcode}
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" xs={6}>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Phone
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={2}>
                                            {details.phone}
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Email
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </Grid>
                    {/* <Grid item justifyContent="center" alignItems="center" xs={12} lg={8}>
                    <MainCard elevation={2} title="Items"></MainCard>
                </Grid> */}
                </Grid>
                {/* <Dialog
                    scroll="paper"
                    fullWidth
                    maxWidth="sm"
                    open={openConfirm}
                    onClose={handleClickConfirmClose}
                    TransitionComponent={Transition}
                >
                    <ConfirmationDialog
                        setOpen={setOpenConfirm}
                        Title="Are you sure ?"
                        Description="You are trying to update the status of a user. Are you want to update this?"
                        onAgree={handleUpdateStatus}
                    />
                </Dialog> */}
            </>
        )
    );
}
