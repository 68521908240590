import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppBar, Toolbar, Typography } from '@mui/material';

export default function ConfirmationDialog({ setOpen, Title, Description, onAgree, onClose }) {
    //   const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
        setOpen(false);
    };

    return (
        <>
            {/* <AppBar sx={{ position: 'relative' }} color="secondary">
                <Toolbar>
                    <Typography sx={{ flex: 1, color: 'white' }} variant="h5" fontSize={17} component="div">
                        {Title}
                    </Typography>
                </Toolbar>
            </AppBar> */}
            <DialogTitle id="alert-dialog-title" fontSize={20}>
                {Title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" fontSize={15}>
                    {Description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    No
                </Button>
                <Button color="secondary" onClick={onAgree}>
                    Yes
                </Button>
            </DialogActions>
        </>
    );
}
