import { Grid, Typography } from '@mui/material';
import { useState } from 'react';

export default function POD({ data }) {
    const [isLoaded, setIsLoaded] = useState(false);
    // const [products, setProducts] = useState(false);
    let productString = '';
    console.log(data);
    if (data) {
        data.cart.map((item) => {
            productString = productString + ` ${item.title} x${item.qty}, `;
        });
        productString = productString.slice(0, -2);
    }
    return (
        <Grid container justifyContent="center" padding={2} pl={4} spacing={2}>
            <Grid container justifyContent="center" alignItems="center" style={{ border: '2px solid black' }} xs={12} lg={12}>
                <Grid item justifyContent="center" alignItems="center" xs={6} pl={1} pt={1}>
                    <Typography variant="h6" fontSize={12} mb={1}>
                        Proof Of Delivery
                    </Typography>
                </Grid>
                <Grid item justifyContent="end" alignItems="end" xs={6} pr={1} pt={1}>
                    <Typography variant="h6" align="right" fontSize={12}>
                        Onet Market
                    </Typography>
                    <Typography variant="h6" align="right" fontSize={12}>
                        77/35, Isuru Place, Mampitiya, Kalegana, Galle
                    </Typography>
                    <Typography variant="h6" align="right" fontSize={12} mb={1}>
                        0716851470
                    </Typography>
                </Grid>
            </Grid>
            <Grid container style={{ border: '2px solid black' }} xs={12} lg={12}>
                <Grid item justifyContent="center" alignItems="center" xs={6} pl={1}>
                    <Typography variant="h6" fontSize={12}>
                        Products: {productString}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" xs={12} lg={12}>
                <Grid container justifyContent="center" alignItems="center" xs={6}>
                    <Grid item justifyContent="center" alignItems="center" style={{ border: '2px solid black' }} xs={12} pl={1} pt={1}>
                        <Typography variant="h6" fontSize={12}>
                            Purchase Date: {data.orderedDate.substring(0, 10)}
                        </Typography>
                        <Typography variant="h6" fontSize={12} mb={1}>
                            Order ID: {data.id}
                        </Typography>
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" style={{ border: '2px solid black' }} xs={12} pl={1} pt={1}>
                        <Typography variant="h6" fontSize={12}>
                            Receiver Details
                        </Typography>
                        <Typography variant="h6" fontSize={12} pl={1} pt={1}>
                            {data.userId}
                        </Typography>
                        <Typography variant="h6" fontSize={12} pl={1}>
                            {data.firstName} {data.lastName}
                        </Typography>
                        <Typography variant="h6" fontSize={12} pl={1}>
                            {data.address}
                        </Typography>
                        <Typography variant="h6" fontSize={12} pl={1}>
                            {data.city}
                        </Typography>
                        <Typography variant="h6" fontSize={12} pl={1}>
                            {data.postcode}
                        </Typography>
                        <Typography variant="h6" fontSize={12} pl={1} pb={1}>
                            {data.phone}, {data.mobile}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item justifyContent="end" alignItems="end" style={{ border: '2px solid black' }} xs={6} pl={1} pt={1}>
                    <Typography variant="h6" fontSize={12}>
                        Referer Details
                    </Typography>
                    {data.refeerer != null ? (
                        <div>
                            <Typography variant="h6" fontSize={12} pl={1} pt={1}>
                                {data.refeerer.userId}
                            </Typography>
                            <Typography variant="h6" fontSize={12} pl={1}>
                                {data.refeerer.firstName} {data.refeerer.lastName}
                            </Typography>
                            <Typography variant="h6" fontSize={12} pl={1} mb={15.4}>
                                {data.refeerer.phone}
                            </Typography>
                        </div>
                    ) : (
                        <div>
                            <Typography variant="h6" fontSize={12} pl={1} pt={1}></Typography>
                            <Typography variant="h6" fontSize={12} pl={1}></Typography>
                            <Typography variant="h6" fontSize={12} pl={1} mb={22.8}></Typography>
                        </div>
                    )}
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" style={{ border: '2px solid black' }} xs={12} lg={12}>
                <Grid item justifyContent="center" alignItems="center" xs={6} pl={1} pt={3}>
                    <Typography variant="h6" fontSize={12}>
                        .................................................................
                    </Typography>
                    <Typography variant="h6" fontSize={12} mb={1} pl={9.5}>
                        Date
                    </Typography>
                </Grid>
                <Grid item justifyContent="end" alignItems="end" xs={6} pr={1} pt={3}>
                    <Typography variant="h6" align="right" fontSize={12}>
                        .................................................................
                    </Typography>
                    <Typography variant="h6" align="right" fontSize={12} mb={1} pr={7.5}>
                        Signature
                    </Typography>
                </Grid>
            </Grid>
            <Grid item justifyContent="center" alignItems="center" xs={12} pr={1}>
                <Typography variant="h6" align="center" fontSize={12} mt={-1}>
                    By signing this document I confirm that above product received in good condition and there is no damages.
                </Typography>
            </Grid>
        </Grid>
    );
}
