// material-ui
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MainCard from 'ui-component/cards/MainCard';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Avatar,
    ButtonBase,
    Dialog,
    Grid,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Slide,
    TablePagination,
    Typography
} from '@mui/material';
import { IconAdjustmentsHorizontal, IconSearch } from '@tabler/icons';
import { useTheme, styled } from '@mui/material/styles';
import { useState } from 'react';
import { Box, ThemeProvider, shouldForwardProp } from '@mui/system';
import EditProductPage from '../edit-products/EditProducts';
import { forwardRef } from 'react';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import { useEffect } from 'react';
import boxImage from 'assets/images/box.jpg';
import ProductImage from 'ui-component/product-image/ProductImage';
import ConfirmationDialog from 'ui-component/confirmation-dialog/ConfirmationDialog';
import { DataGrid } from '@mui/x-data-grid';
// ==============================|| Search Product PAGE ||============================== //

function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const rows = [
//     createData('T1234', 'Frozen yoghurt', 159, 6.0),
//     createData('T134', 'Ice cream sandwich', 237, 9.0),
//     createData('T234', 'Eclair', 262, 16.0),
//     createData('T123', 'Cupcake', 305, 3.7),
//     createData('T14', 'Gingerbread', 356, 16.0)
// ];

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

const imgBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/ostore-86bf8.appspot.com/o/';

export default function SearchProductPage() {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [value, setValue] = useState('');
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [deletingId, setDeleteingId] = useState();
    const [products, setProducts] = useState([]);
    const [rows, setRows] = useState([]);

    const columns = [
        { field: 'id', headerName: 'ID', minWidth: 100, flex: 1 },
        {
            field: 'title',
            headerName: 'Title',
            minWidth: 400,
            flex: 1,
            renderCell: (params) => (
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item justifyContent="center" alignItems="center" xs={3}>
                        {params.value.img == undefined ? (
                            <ProductImage
                                styleDetails={{ width: 60, borderRadius: 5, zIndex: 2 }}
                                src={`${imgBaseUrl + '?alt=media'}`}
                                placeholder={boxImage}
                            />
                        ) : (
                            <ProductImage
                                styleDetails={{ width: 60, borderRadius: 5, zIndex: 2 }}
                                src={`${imgBaseUrl + params.value.img.replace(/\//g, '%2F') + '?alt=media'}`}
                                placeholder={boxImage}
                            />
                        )}
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" xs={9}>
                        <Typography variant="h6" fontSize={14} mb={1}>
                            {params.value.title}
                        </Typography>
                    </Grid>
                </Grid>
            )
        },
        {
            field: 'price',
            headerName: 'Price',
            type: 'number',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'qty',
            headerName: 'Quantity',
            type: 'number',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'allData',
            headerName: '',
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <IconButton
                        color="secondary"
                        component="label"
                        onClick={() => {
                            handleClickOpen();
                            setSelectedRow(params.value);
                        }}
                    >
                        <Edit />
                    </IconButton> */}
                    <IconButton
                        color="secondary"
                        component="label"
                        sx={{
                            ml: 2
                        }}
                        onClick={() => {
                            handleClickConfirmOpen();
                            setDeleteingId(params.value.id);
                        }}
                    >
                        <DeleteIcon color="error" />
                    </IconButton>
                </div>
            ),
            minWidth: 100,
            flex: 1
        }
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleClickConfirmClose = () => {
        setOpenConfirm(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function deleteProducts(id) {
        HttpCommon.delete('/api/product', {
            data: {
                id: id
            }
        })
            .then((res) => {
                console.log(res.data);
                getProducts();
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getProducts() {
        HttpCommon.get('/api/product')
            .then((res) => {
                console.log(res.data);
                setProducts(res.data.data);
                const tempArr = [];
                res.data.data.map((element) => {
                    tempArr.push({
                        id: element.id,
                        title: {
                            title: element.title,
                            img: element.img[0]
                        },
                        price: element.price,
                        qty: element.qty,
                        allData: element
                    });
                });
                setRows(tempArr);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getProductsById() {
        console.log('id = ' + id);
        if (id !== undefined && id != '') {
            HttpCommon.get(`/api/product/id/${id}`)
                .then((res) => {
                    console.log(res.data);
                    setProducts(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            getProducts();
        }
    }

    function getProductsByTitle() {
        console.log('title = ' + title);
        if (title !== undefined && title !== '') {
            HttpCommon.get(`/api/product/title/${title}`)
                .then((res) => {
                    console.log(res.data);
                    setProducts(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            getProducts();
        }
    }

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <MainCard title="Search Products">
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <OutlinedInput
                        sx={{ width: '100%', pr: 1, pl: 2, my: 0 }}
                        id="input-search-profile"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                getProductsById();
                            }
                        }}
                        placeholder="Search by ID"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconSearch stroke={1.5} size="1.3rem" onClick={getProductsById} />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{
                            'aria-label': 'weight'
                        }}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <OutlinedInput
                        sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                        id="input-search-profile"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                getProductsByTitle();
                            }
                        }}
                        placeholder="Search by Title"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconSearch stroke={1.5} size="1.3rem" onClick={getProductsByTitle} />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{
                            'aria-label': 'weight'
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer elevation={2} component={Paper}>
                <Box sx={{ height: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            getRowHeight={() => 'auto'}
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10
                                    }
                                }
                            }}
                            pageSizeOptions={[5]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                            // isRowSelectable={false}
                            onRowClick={(event, rowData) => {
                                handleClickOpen();
                                setSelectedRow(event.row.allData);
                            }}
                            isCellEditable={false}
                        />
                    </ThemeProvider>
                </Box>
            </TableContainer>

            {/* <TableContainer elevation={2} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="left">Title</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right">Direct Commision</TableCell>
                            <TableCell align="right">Direct Commision</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="center">
                                    <ProductImage
                                        styleDetails={{ width: 60, borderRadius: 5, zIndex: 2 }}
                                        src={imgBaseUrl + row.img[0]}
                                        placeholder={boxImage}
                                    />
                                </TableCell>
                                <TableCell align="left">{row.title}</TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                                <TableCell align="right">{row.directCommision}</TableCell>
                                <TableCell align="center">
                                    <div style={{ display: 'flex' }}>
                                        <IconButton
                                            color="secondary"
                                            component="label"
                                            onClick={() => {
                                                handleClickOpen();
                                                setSelectedRow(row);
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            component="label"
                                            sx={{
                                                ml: 2
                                            }}
                                            onClick={() => {
                                                handleClickConfirmOpen();
                                                setDeleteingId(row.id);
                                            }}
                                        >
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog fullScreen open={open} onClose={handleClickOpen} TransitionComponent={Transition}>
                <EditProductPage open={open} setOpen={setOpen} rowDetails={selectedRow} onEnd={getProducts} />
            </Dialog>
            <Dialog
                scroll="paper"
                fullWidth
                maxWidth="sm"
                open={openConfirm}
                onClose={handleClickConfirmClose}
                TransitionComponent={Transition}
            >
                <ConfirmationDialog
                    setOpen={setOpenConfirm}
                    Title="Are you sure ?"
                    Description="You are trying to delete a product. Are you want to delete this?"
                    onAgree={() => deleteProducts(deletingId)}
                />
            </Dialog>
        </MainCard>
    );
}
