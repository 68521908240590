// material-ui
import React, { useRef } from 'react';
import { Button, Dialog, Grid, Slide, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import 'animate.css/animate.min.css';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import { useState, forwardRef } from 'react';
import CatogorySelector from 'ui-component/category-selector/CategorySelector';
import { styled } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';
import CommisionTable from 'ui-component/CommissionTable';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// ==============================|| Add Product PAGE ||============================== //

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddProductPage() {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [commision, setCommision] = useState(false);
    const [values, setValues] = useState({
        id: '',
        title: '',
        brand: '',
        img: [],
        description: '',
        price: '',
        subTitle: '',
        note: '',
        features: '',
        category: '',
        sCategory: '',
        directCommision: '',
        indirectCommision: '',
        qty: '',
        shippingFee: ''
    });
    const [rows, setRows] = useState([]);

    const editorRef = useRef(null);

    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickOpen1 = () => {
        if (rows.length < 10) {
            setOpen1(!open1);
        } else {
            messages.addMessage({ title: 'Error!', msg: 'Commssions Can be added only up to 3 levels', type: 'danger' });
        }
    };

    const handleCommision = (event) => {
        setCommision(event.target.value);
    };

    const handleClickCommsionSubmit = () => {
        setRows((rows) => [...rows, { level: rows.length + 1, commision: parseFloat(commision) }]);
        handleClickOpen1(false);
        console.log(rows);
    };

    const handleClearTable = () => {
        setRows([]);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    // specify upload params and url for your files
    const getUploadParams = ({ meta }) => {
        return { url: ' ' };
    };

    // called every time a file's `status` changes
    const handleChangeStatus = (file, status) => {
        console.log('status, meta, file');
        console.log(status, file);
        const tempArr = values.img;
        console.log(tempArr);
        console.log(values.img);
        if (status === 'ready') {
            tempArr.push(file);
            setValues({ ...values, img: tempArr });
        } else if (status === 'removed') {
            const fileIndex = tempArr.indexOf(file); // get  "file" index
            // remove file from the img array
            tempArr.splice(fileIndex, 1);
            setValues({ ...values, img: tempArr });
        }
    };

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = () => {
        // console.log(files.map((f) => f.meta));
        const key = localStorage.getItem('userID');
        const formData = new FormData();
        values.img.map((image, index) => {
            console.log(image);
            console.log(image.file);
            // let file = fs.readFileSync(image.file, { encoding: 'base64' });
            formData.append(`files`, image.file);
        });
        formData.append('id', values.id);
        formData.append('title', values.title);
        formData.append('brand', values.brand);
        formData.append('description', editorRef.current.getContent());
        formData.append('price', values.price);
        formData.append('subTitle', values.subTitle);
        formData.append('note', values.note);
        formData.append('features', values.features);
        formData.append('shippingFee', values.shippingFee);
        formData.append('category', values.category);
        formData.append('sCategory', values.sCategory);
        formData.append('directCommision', values.directCommision);
        formData.append('indirectCommision', values.indirectCommision);
        formData.append('qty', values.qty);
        formData.append('commisionLevel', JSON.stringify(rows));
        for (const pair of formData.entries()) {
            console.log(`${pair[0]} , ${pair[1]}`);
        }
        HttpCommon.post(`/api/product/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Uploaded!', msg: 'Product Created Succesfully', type: 'success' });
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <MainCard title="Add Products">
            <Grid container justifyContent="center" alignItems="center" mt={2} spacing={2}>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Id" onChange={handleChange('id')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Title" onChange={handleChange('title')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Brand" onChange={handleChange('brand')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Price" type="number" onChange={handleChange('price')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Quantity" type="number" onChange={handleChange('qty')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField
                        id="outlined-error"
                        fullWidth
                        label="Direct Commision"
                        type="number"
                        onChange={handleChange('directCommision')}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField
                        id="outlined-error"
                        fullWidth
                        label="Indirect Commision"
                        type="number"
                        onChange={handleChange('indirectCommision')}
                    />
                </Grid>
                <Grid container justifyContent="center" alignItems="center" mt={2} ml={0.4} spacing={2} xs={12}>
                    {values.category !== undefined && values.category !== '' && (
                        <Grid item justifyContent="center" alignItems="center" xs={12} md={4}>
                            <TextField
                                id="outlined-error"
                                disabled
                                value={values.category}
                                fullWidth
                                label="Category"
                                onChange={handleChange('category')}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000000AA'
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    {values.sCategory !== undefined && values.sCategory !== '' && (
                        <Grid item justifyContent="center" alignItems="center" xs={12} md={4}>
                            <TextField
                                id="outlined-error"
                                disabled
                                value={values.sCategory}
                                fullWidth
                                label="Sub Category"
                                onChange={handleChange('sCategory')}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000000AA'
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item justifyContent="center" alignItems="center" xs={4}>
                        <Box sx={{ mb: 1 }}>
                            <AnimateButton>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setOpen(true)}
                                >
                                    Select Category
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" type="number" fullWidth label="Shipping Fee" onChange={handleChange('shippingFee')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Sub Title" onChange={handleChange('subTitle')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    {/* <TextField
                        id="outlined-multiline-static"
                        fullWidth
                        label="Description"
                        onChange={handleChange('description')}
                        multiline
                        rows={4}
                    /> */}
                    <h3>Description</h3>
                    <Editor
                        apiKey="z3vno42955goouz6gfxewvyakz4smnx8bcxfwtrtgpub5n8b"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue=""
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist',
                                'autolink',
                                'lists',
                                'link',
                                'image',
                                'charmap',
                                'preview',
                                'anchor',
                                'searchreplace',
                                'visualblocks',
                                'code',
                                'fullscreen',
                                'insertdatetime',
                                'media',
                                'table',
                                'code',
                                'help',
                                'wordcount'
                            ],
                            toolbar:
                                'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    <TextField id="outlined-multiline-static" fullWidth label="Note" onChange={handleChange('note')} multiline rows={4} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        fullWidth
                        label="Features"
                        onChange={handleChange('features')}
                        multiline
                        rows={4}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    <Dropzone
                        styles={{
                            dropzoneReject: {
                                borderColor: 'black',
                                minHeight: 200,
                                maxHeight: 1000,
                                borderRadius: 10,
                                borderWidth: 1
                            },
                            dropzone: {
                                borderColor: '#CDD5DF',
                                minHeight: 200,
                                maxHeight: 1000,
                                borderRadius: 10,
                                borderWidth: 1,
                                '&:hover': {
                                    borderColor: 'black'
                                },
                                overflowY: 'hidden',
                                overflowX: 'hidden'
                            },
                            inputLabel: { color: '#3c52b2' },
                            inputLabelWithFiles: { color: '#3c52b2', marginBottom: 18 }
                        }}
                        SubmitButtonComponent={null}
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        autoUpload={false}
                        accept="image/*,audio/*,video/*"
                    />
                </Grid>
                <Grid container mt={2} ml={0.4} spacing={2} xs={12}>
                    <Grid item xs={4}>
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button size="large" type="submit" variant="contained" color="info" onClick={handleClickOpen1}>
                                    Add Commision
                                </Button>
                            </AnimateButton>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button size="large" type="submit" variant="contained" color="error" onClick={() => handleClearTable()}>
                                    Clear Table
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <CommisionTable rows={rows} />
                    </Grid>
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={4}>
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button fullWidth size="large" type="submit" variant="contained" color="secondary" onClick={handleSubmit}>
                                Add Product
                            </Button>
                        </AnimateButton>
                    </Box>
                </Grid>
            </Grid>
            <Dialog scroll="paper" fullWidth open={open} maxWidth="sm" onClose={handleClickOpen} TransitionComponent={Transition}>
                <CatogorySelector open={open} setOpen={setOpen} setValues={setValues} data={values} />
            </Dialog>
            <Dialog open={open1} onClose={handleClickOpen1}>
                <DialogTitle>
                    <h2>Add Commision</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter Commission amount for Commision Level {rows.length + 1}</DialogContentText>
                    <TextField
                        required
                        margin="dense"
                        id="camount"
                        name="camount"
                        label="Amount"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={handleCommision}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickOpen1}>Cancel</Button>
                    <Button type="submit" onClick={handleClickCommsionSubmit}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Grid container justifyContent="center" alignItems="center" mt={2} xs={12} lg={6} spacing={2}>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <Typography align="center"> Product Name </Typography>
                </Grid>
                <Grid item justifyContent="flex-end" xs={12} md={6}>
                    <TextField align="center" fullWidth id="outlined-error" label="Name" />
                </Grid>
            </Grid> */}
        </MainCard>
    );
}
