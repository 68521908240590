// material-ui
import React, { useRef } from 'react';
import {
    AppBar,
    Button,
    Dialog,
    Divider,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Slide,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { shouldForwardProp } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import Dropzone from 'react-dropzone-uploader';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CatogorySelector from 'ui-component/category-selector/CategorySelector';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import { Editor } from '@tinymce/tinymce-react';
import boxImage from 'assets/images/box.jpg';
import CommisionTable from 'ui-component/CommissionTable';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// ==============================|| Edit Product PAGE ||============================== //

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledButton = styled(Button, { shouldForwardProp })(({ theme }) => ({
    // cursor: 'pointer',
    // padding: 16,
    '&:hover': {
        // background: theme.palette.primary
        backgroundColor: '#fff',
        color: '#3c52b2'
    }
}));

// specify upload params and url for your files
const getUploadParams = ({ meta }) => {
    return { url: ' ' };
};

const imgBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/ostore-86bf8.appspot.com/o/';

export default function EditProductPage({ open, setOpen, rowDetails, onEnd }) {
    const editorRef = useRef();
    const [initialFile, setInitialFile] = useState([
        'https://www.glifestyle.net/-/media/sites/shared/products/home-and-living/gl/7704016009/7704016009-01.ashx?h=580&w=580&la=en&hash=1DF431327A47AD50893D4EEA5B2D216812BFF8D9',
        'https://www.glifestyle.net/-/media/sites/shared/products/home-and-living/gl/7704016009/7704016009-02.ashx?h=580&w=580&la=en&hash=4690529E2865900E1385E2CBF86AB8AB6C153F32'
    ]);
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [commision, setCommision] = useState(false);
    const [values, setValues] = useState({
        id: '',
        title: '',
        brand: '',
        img: [],
        newImg: [],
        files: [],
        description: '',
        price: '',
        subTitle: '',
        note: '',
        features: '',
        shippingFee: '',
        category: '',
        sCategory: '',
        qty: '',
        directCommision: '',
        indirectCommision: ''
    });
    const [rows, setRows] = useState([]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickClose = () => {
        setCategoryOpen(false);
    };

    // called every time a file's `status` changes
    const handleChangeStatus = (file, status) => {
        console.log('status, meta, file');
        console.log(status, file);
        const tempArr = values.newImg;
        console.log(tempArr);
        console.log(values.newImg);
        if (status === 'ready') {
            console.log('---------------------------------------------------------------');
            tempArr.push(file);
            console.log(tempArr);
            setValues({ ...values, newImg: tempArr });
        } else if (status === 'removed') {
            const fileIndex = tempArr.indexOf(file); // get  "file" index
            // remove file from the img array
            //tempArr.splice(fileIndex, 1);
            setValues({ ...values, newImg: tempArr });
        }
    };

    const handleClickOpen1 = () => {
        if (rows.length < 10) {
            setOpen1(!open1);
        } else {
            messages.addMessage({ title: 'Error!', msg: 'Commssions Can be added only up to 3 levels', type: 'danger' });
        }
    };

    const handleCommision = (event) => {
        setCommision(event.target.value);
    };

    const handleClickCommsionSubmit = () => {
        setRows((rows) => [...rows, { level: rows.length + 1, commision: parseFloat(commision) }]);
        handleClickOpen1(false);
        console.log(rows);
    };

    const handleClearTable = () => {
        setRows([]);
    };
    const handleSubmit = () => {
        const formData = new FormData();
        if (values.newImg !== undefined && values.newImg.length > 0) {
            values.newImg.map((image, index) => {
                console.log(image);
                console.log(image.file);
                // let file = fs.readFileSync(image.file, { encoding: 'base64' });
                formData.append(`files`, image.file);
            });
        }

        formData.append('id', values.id);
        formData.append('title', values.title);
        formData.append('brand', values.brand);
        formData.append('img', values.img);
        formData.append('description', values.description);
        formData.append('price', values.price);
        formData.append('subTitle', values.subTitle);
        formData.append('note', values.note);
        formData.append('features', values.features);
        formData.append('shippingFee', values.shippingFee);
        formData.append('category', values.category);
        formData.append('sCategory', values.sCategory);
        formData.append('directCommision', values.directCommision);
        formData.append('indirectCommision', values.indirectCommision);
        formData.append('qty', values.qty);
        formData.append('commisionLevel', JSON.stringify(rows));
        for (const pair of formData.entries()) {
            console.log(`${pair[0]} , ${pair[1]}`);
        }
        HttpCommon.put(`/api/product/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Uploaded!', msg: 'Product Updated Succesfully', type: 'success' });
                }
                onEnd();
                setOpen(false);
            })
            .catch((err) => {
                console.log(err);
                setOpen(false);
            });
    };

    useEffect(() => {
        console.log(rowDetails.img);
        setValues({
            id: rowDetails.id,
            title: rowDetails.title,
            brand: rowDetails.brand,
            img: rowDetails.img,
            newImg: [],
            description: rowDetails.description,
            price: rowDetails.price,
            subTitle: rowDetails.subTitle,
            note: rowDetails.note,
            features: rowDetails.features,
            witb: rowDetails.witb,
            category: rowDetails.category,
            sCategory: rowDetails.sCategory,
            qty: rowDetails.qty,
            directCommision: rowDetails.directCommision,
            indirectCommision: rowDetails.indirectCommision,
            shippingFee: rowDetails.shippingFee
        });
        setRows(rowDetails.commisionLevel);
    }, []);

    return (
        <>
            <AppBar sx={{ position: 'relative' }} color="secondary">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="subtitle1" component="div">
                        Edit Product
                    </Typography>
                    <StyledButton color="inherit" onClick={handleSubmit}>
                        save
                    </StyledButton>
                </Toolbar>
            </AppBar>
            <Grid container justifyContent="center" alignItems="center" padding={3} spacing={2}>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Id" value={values.id} onChange={handleChange('id')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Title" value={values.title} onChange={handleChange('title')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Brand" value={values.brand} onChange={handleChange('brand')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Price" value={values.price} onChange={handleChange('price')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Quantity" value={values.qty} onChange={handleChange('qty')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField
                        id="outlined-error"
                        fullWidth
                        label="Direct Commision"
                        value={values.directCommision}
                        onChange={handleChange('directCommision')}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField
                        id="outlined-error"
                        fullWidth
                        label="Indirect Commision"
                        value={values.indirectCommision}
                        onChange={handleChange('indirectCommision')}
                    />
                </Grid>
                <Grid container justifyContent="center" alignItems="center" mt={2} ml={0.4} spacing={2} xs={12}>
                    {values.category !== undefined && values.category !== '' && (
                        <Grid item justifyContent="center" alignItems="center" xs={12} md={4}>
                            <TextField
                                id="outlined-error"
                                disabled
                                value={values.category}
                                fullWidth
                                label="Category"
                                onChange={handleChange('category')}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000000AA'
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    {values.sCategory !== undefined && values.sCategory !== '' && (
                        <Grid item justifyContent="center" alignItems="center" xs={12} md={4}>
                            <TextField
                                id="outlined-error"
                                disabled
                                value={values.sCategory}
                                fullWidth
                                label="Sub Category"
                                onChange={handleChange('sCategory')}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000000AA'
                                    }
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item justifyContent="center" alignItems="center" xs={4}>
                        <Box sx={{ mb: 1 }}>
                            <AnimateButton>
                                <Button
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setCategoryOpen(true)}
                                >
                                    Select Category
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    {/* <TextField
                        id="outlined-multiline-static"
                        fullWidth
                        label="Description"
                        multiline
                        minRows={2}
                        maxRows={20}
                        value={values.description}
                        onChange={handleChange('description')}
                    /> */}
                    <h3>Description</h3>
                    <Editor
                        apiKey="z3vno42955goouz6gfxewvyakz4smnx8bcxfwtrtgpub5n8b"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={values.description}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist',
                                'autolink',
                                'lists',
                                'link',
                                'image',
                                'charmap',
                                'preview',
                                'anchor',
                                'searchreplace',
                                'visualblocks',
                                'code',
                                'fullscreen',
                                'insertdatetime',
                                'media',
                                'table',
                                'code',
                                'help',
                                'wordcount'
                            ],
                            toolbar:
                                'undo redo | blocks | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField
                        id="outlined-error"
                        fullWidth
                        label="Shipping Fee"
                        value={values.shippingFee}
                        onChange={handleChange('shippingFee')}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField
                        id="outlined-error"
                        fullWidth
                        label="Sub Title"
                        value={values.subTitle}
                        onChange={handleChange('subTitle')}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        fullWidth
                        label="Note"
                        multiline
                        minRows={2}
                        maxRows={20}
                        value={values.note}
                        onChange={handleChange('note')}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        fullWidth
                        label="Features"
                        multiline
                        minRows={2}
                        maxRows={20}
                        value={values.features}
                        onChange={handleChange('features')}
                    />
                </Grid>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    {values.img != undefined &&
                        values.img.length > 0 &&
                        values.img.map((item) => (
                            <Grid item justifyContent="center" alignItems="center" xs={12} md={3} mt={2} mb={-10} p={10}>
                                <ImageListItem key={item}>
                                    <img
                                        src={`${imgBaseUrl + item.replace(/\//g, '%2F') + '?alt=media'}`}
                                        srcSet={`${imgBaseUrl + item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        alt="Product"
                                        loading="lazy"
                                        placeholder={boxImage}
                                    />
                                    <ImageListItemBar
                                        position="top"
                                        style={{ backgroundColor: 'transparent' }}
                                        actionIcon={
                                            <IconButton
                                                color="error"
                                                sx={{ mr: -1 }}
                                                onClick={() => {
                                                    console.log(item);
                                                    let images = values.img;
                                                    let imgIndex = images.indexOf(item);
                                                    images.splice(imgIndex, 1);
                                                    console.log(images);
                                                    setValues({ ...values, img: images });
                                                }}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            </Grid>
                        ))}
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    <Dropzone
                        styles={{
                            dropzoneReject: {
                                borderColor: 'black',
                                minHeight: 200,
                                maxHeight: 1000,
                                borderRadius: 10,
                                borderWidth: 1
                            },
                            dropzone: {
                                borderColor: '#CDD5DF',
                                minHeight: 200,
                                maxHeight: 1000,
                                borderRadius: 10,
                                borderWidth: 1,
                                '&:hover': {
                                    borderColor: 'black'
                                },
                                overflowY: 'hidden',
                                overflowX: 'hidden'
                            },
                            inputLabel: { color: '#3c52b2' },
                            inputLabelWithFiles: { color: '#3c52b2', marginBottom: 18 }
                        }}
                        SubmitButtonComponent={null}
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        autoUpload={false}
                        accept="image/*,audio/*,video/*"
                    />
                </Grid>
                <Grid container mt={2} ml={0.4} spacing={2} xs={12}>
                    <Grid item xs={4}>
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button size="large" type="submit" variant="contained" color="info" onClick={handleClickOpen1}>
                                    Add Commision
                                </Button>
                            </AnimateButton>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button size="large" type="submit" variant="contained" color="error" onClick={() => handleClearTable()}>
                                    Clear Table
                                </Button>
                            </AnimateButton>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <CommisionTable rows={rows} />
                    </Grid>
                </Grid>
            </Grid>
            <Dialog scroll="paper" fullWidth open={categoryOpen} maxWidth="sm" onClose={handleClickClose} TransitionComponent={Transition}>
                <CatogorySelector open={categoryOpen} setOpen={setCategoryOpen} setValues={setValues} data={values} />
            </Dialog>
            <Dialog open={open1} onClose={handleClickOpen1}>
                <DialogTitle>
                    <h2>Add Commision</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter Commission amount for Commision Level {rows.length + 1}</DialogContentText>
                    <TextField
                        required
                        margin="dense"
                        id="camount"
                        name="camount"
                        label="Amount"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={handleCommision}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickOpen1}>Cancel</Button>
                    <Button type="submit" onClick={handleClickCommsionSubmit}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
