// material-ui
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MainCard from 'ui-component/cards/MainCard';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Button, ButtonBase, Dialog, Grid, IconButton, InputAdornment, OutlinedInput, Slide, TablePagination } from '@mui/material';
import { IconAdjustmentsHorizontal, IconSearch } from '@tabler/icons';
import { useTheme, styled } from '@mui/material/styles';
import { useState } from 'react';
import { borderRadius, shouldForwardProp } from '@mui/system';
import EditCategoriesPage from '../edit-categories/EditCategories';
import { forwardRef } from 'react';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import { useEffect } from 'react';
import boxImage from 'assets/images/box.jpg';
import ConfirmationDialog from 'ui-component/confirmation-dialog/ConfirmationDialog';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AddCategoriesPage from '../add-categories/AddCategories';
// ==============================|| Search Categories PAGE ||============================== //

function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const rows = [
//     createData('T1234', 'Frozen yoghurt', 159, 6.0),
//     createData('T134', 'Ice cream sandwich', 237, 9.0),
//     createData('T234', 'Eclair', 262, 16.0),
//     createData('T123', 'Cupcake', 305, 3.7),
//     createData('T14', 'Gingerbread', 356, 16.0)
// ];

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

export default function SearchCategoriesPage() {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [value, setValue] = useState('');
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openAddCategory, setOpenAddCategory] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [deletingId, setDeleteingId] = useState();
    const [categories, setCategories] = useState([]);
    // const [rows, setRows] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleClickConfirmClose = () => {
        setOpenConfirm(false);
    };

    const handleClickAddCategoryOpen = () => {
        setOpenAddCategory(true);
    };

    const handleClickAddCategoryClose = () => {
        setOpenAddCategory(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function deleteCategories(id) {
        const data = {
            id: id
        };
        HttpCommon.delete('/api/category', { data: data })
            .then((res) => {
                console.log(res.data);
                getCategories();
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getCategories = () => {
        HttpCommon.get('/api/category')
            .then((res) => {
                console.log(res.data);
                setCategories(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function getCategoriesById() {
        console.log('id = ' + id);
        if (id !== undefined && id != '') {
            HttpCommon.get(`/api/category/id/${id}`)
                .then((res) => {
                    console.log(res.data);
                    setCategories(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            getCategories();
        }
    }

    function getCategoriesByTitle() {
        console.log('title = ' + title);
        if (title !== undefined && title !== '') {
            HttpCommon.get(`/api/category/title/${title}`)
                .then((res) => {
                    console.log(res.data);
                    setCategories(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            getCategories();
        }
    }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = () => {
        // console.log(files.map((f) => f.meta));
        const key = localStorage.getItem('userID');
        const formData = new FormData();
        values.img.map((image, index) => {
            console.log(image);
            console.log(image.file);
            // let file = fs.readFileSync(image.file, { encoding: 'base64' });
            formData.append(`file[${index}]`, image.file);
        });
        formData.append('id', values.id);
        formData.append('title', values.title);
        formData.append('description', values.description);
        formData.append('price', values.price);
        formData.append('subTitle', values.subTitle);
        formData.append('note', values.note);
        formData.append('features', values.features);
        formData.append('witb', values.witb);
        formData.append('category', values.category);
        formData.append('sCategory', values.sCategory);
        formData.append('directCommision', values.directCommision);
        formData.append('indirectCommision', values.indirectCommision);
        formData.append('qty', values.qty);
        for (const pair of formData.entries()) {
            console.log(`${pair[0]} , ${pair[1]}`);
        }
        HttpCommon.post(`/api/Categories/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Uploaded!', msg: 'Categories Created Succesfully', type: 'success' });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <MainCard
            title="Search Categories"
            secondary={
                <AnimateButton>
                    <Button
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={handleClickAddCategoryOpen}
                        sx={{ borderRadius: 20 }}
                    >
                        Add Category
                    </Button>
                </AnimateButton>
            }
        >
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <OutlinedInput
                        sx={{ width: '100%', pr: 1, pl: 2, my: 0 }}
                        id="input-search-profile"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                getCategoriesById();
                            }
                        }}
                        placeholder="Search by ID"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconSearch stroke={1.5} size="1.3rem" onClick={getCategoriesById} />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{
                            'aria-label': 'weight'
                        }}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <OutlinedInput
                        sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                        id="input-search-profile"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                getCategoriesByTitle();
                            }
                        }}
                        placeholder="Search by Title"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconSearch stroke={1.5} size="1.3rem" onClick={getCategoriesByTitle} />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{
                            'aria-label': 'weight'
                        }}
                    />
                </Grid>
            </Grid>

            <TableContainer elevation={2} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Head</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.head}</TableCell>
                                <TableCell align="right">
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <IconButton
                                            color="secondary"
                                            component="label"
                                            onClick={() => {
                                                handleClickOpen();
                                                setSelectedRow(row);
                                            }}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            component="label"
                                            sx={{
                                                ml: 2
                                            }}
                                            onClick={() => {
                                                handleClickConfirmOpen();
                                                setDeleteingId(row.id);
                                            }}
                                        >
                                            <DeleteIcon color="error" />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={categories.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog scroll="paper" fullWidth maxWidth="sm" open={open} onClose={handleClickOpen} TransitionComponent={Transition}>
                <EditCategoriesPage open={open} setOpen={setOpen} rowDetails={selectedRow} onEnd={getCategories} />
            </Dialog>
            <Dialog
                scroll="paper"
                fullWidth
                maxWidth="sm"
                open={openConfirm}
                onClose={handleClickConfirmClose}
                TransitionComponent={Transition}
            >
                <ConfirmationDialog
                    setOpen={setOpenConfirm}
                    Title="Are you sure ?"
                    Description="You are trying to delete a category. Are you want to delete this?"
                    onAgree={() => deleteCategories(deletingId)}
                />
            </Dialog>
            <Dialog
                scroll="paper"
                fullWidth
                open={openAddCategory}
                maxWidth="sm"
                onClose={handleClickAddCategoryClose}
                TransitionComponent={Transition}
            >
                <AddCategoriesPage open={openAddCategory} setOpen={setOpenAddCategory} onEnd={getCategories} />
            </Dialog>
        </MainCard>
    );
}
