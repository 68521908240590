// material-ui
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MainCard from 'ui-component/cards/MainCard';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, ButtonBase, Chip, Dialog, Grid, IconButton, InputAdornment, OutlinedInput, Slide, TablePagination } from '@mui/material';
import { IconAdjustmentsHorizontal, IconSearch } from '@tabler/icons';
import { useTheme, styled } from '@mui/material/styles';
import { useState } from 'react';
import { Box, shouldForwardProp } from '@mui/system';
import { forwardRef } from 'react';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import { useEffect } from 'react';
import boxImage from 'assets/images/box.jpg';
import ProductImage from 'ui-component/product-image/ProductImage';
import ConfirmationDialog from 'ui-component/confirmation-dialog/ConfirmationDialog';
import ViewOrderPage from '../view-orders/ViewOrders';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// ==============================|| Search Order PAGE ||============================== //

const theme = createTheme({
    palette: {
        primary: {
            main: '#1b5e20'
        },
        secondary: {
            main: '#e65100'
        }
    }
});

function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// const rows = [
//     createData('T1234', 'Frozen yoghurt', 159, 6.0),
//     createData('T134', 'Ice cream sandwich', 237, 9.0),
//     createData('T234', 'Eclair', 262, 16.0),
//     createData('T123', 'Cupcake', 305, 3.7),
//     createData('T14', 'Gingerbread', 356, 16.0)
// ];

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    '&:hover': {
        background: theme.palette.secondary.dark,
        color: theme.palette.secondary.light
    }
}));

const imgBaseUrl = 'https://starfish-app-vil5p.ondigitalocean.app/uploads/';

export default function SearchOrderPage() {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [value, setValue] = useState('');
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [deletingId, setDeleteingId] = useState();
    const [orders, setOrders] = useState([]);
    const [rows, setRows] = useState([]);

    const columns = [
        { field: 'id', headerName: 'ID', minWidth: 100, flex: 1 },
        {
            field: 'firstName',
            headerName: 'First name',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'phone',
            headerName: 'Contact No',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'orderAmount',
            headerName: 'Total Amount',
            type: 'number',
            minWidth: 100,
            flex: 1,
            editable: false
        },
        {
            field: 'orderedDate',
            headerName: 'Order Date',
            sortable: true,
            minWidth: 100,
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            renderCell: (params) => getStatusChip(params.value),
            minWidth: 100,
            flex: 1
        }
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleClickConfirmClose = () => {
        setOpenConfirm(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRowClick = (event, rowData) => {
        console.log(event);
        handleClickOpen();
        setSelectedRow(event.row.allData);
    };

    function getStatusChip(status) {
        console.log(status);
        switch (status) {
            case 'unpaid':
                return <Chip label="Unpaid" color="warning" />;
            case 'processing':
                return <Chip label="Processing" color="info" />;
            case 'shipped':
                return <Chip label="Shipped" color="secondary" />;
            case 'completed':
                return <Chip label="Completed" color="success" />;
            case 'cancel':
                return <Chip label="Cancel" color="error" />;
            case 'return':
                return <Chip label="Return" color="error" />;
            default:
                return <Chip label="Unknown" />;
        }
    }

    function deleteOrders(id) {
        HttpCommon.delete('/api/order', {
            data: {
                id: id
            }
        })
            .then((res) => {
                console.log(res.data);
                getOrders();
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getOrders() {
        HttpCommon.get('/api/order')
            .then((res) => {
                console.log(res.data);
                setOrders(res.data.data);
                const tempArr = [];
                res.data.data.map((element) => {
                    tempArr.push({
                        id: element.id,
                        firstName: element.firstName,
                        phone: element.phone,
                        orderAmount: element.orderAmount,
                        orderedDate: element.orderedDate.substring(0, 10),
                        status: element.status,
                        allData: element
                    });
                });
                setRows(tempArr);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getOrdersById() {
        console.log('id = ' + id);
        if (id !== undefined && id != '') {
            HttpCommon.get(`/api/order/id/${id}`)
                .then((res) => {
                    console.log(res.data);
                    setOrders(res.data.data);
                    const tempArr = [];
                    res.data.data.map((element) => {
                        tempArr.push({
                            id: element.id,
                            firstName: element.firstName,
                            phone: element.phone,
                            orderAmount: element.orderAmount,
                            orderedDate: element.orderedDate.substring(0, 10),
                            status: element.status,
                            allData: element
                        });
                    });
                    setRows(tempArr);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            getOrders();
        }
    }

    function getOrdersByTitle() {
        console.log('title = ' + title);
        if (title !== undefined && title !== '') {
            HttpCommon.get(`/api/order/title/${title}`)
                .then((res) => {
                    console.log(res.data);
                    setOrders(res.data.data);
                    const tempArr = [];
                    res.data.data.map((element) => {
                        tempArr.push({
                            id: element.id,
                            firstName: element.firstName,
                            phone: element.phone,
                            orderAmount: element.orderAmount,
                            orderedDate: element.orderedDate.substring(0, 10),
                            status: element.status
                        });
                    });
                    setRows(tempArr);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            getOrders();
        }
    }

    useEffect(() => {
        getOrders();
    }, []);

    return (
        <MainCard title="Search Orders">
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <OutlinedInput
                        sx={{ width: '100%', pr: 1, pl: 2, my: 0 }}
                        id="input-search-profile"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                getOrdersById();
                            }
                        }}
                        placeholder="Search by ID"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconSearch stroke={1.5} size="1.3rem" onClick={getOrdersById} />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{
                            'aria-label': 'weight'
                        }}
                    />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <OutlinedInput
                        sx={{ width: '100%', pr: 1, pl: 2, my: 2 }}
                        id="input-search-profile"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                getOrdersByTitle();
                            }
                        }}
                        placeholder="Search by Title"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" color={theme.palette.grey[500]} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <ButtonBase sx={{ borderRadius: '12px' }}>
                                    <HeaderAvatarStyle variant="rounded">
                                        <IconSearch stroke={1.5} size="1.3rem" onClick={getOrdersByTitle} />
                                    </HeaderAvatarStyle>
                                </ButtonBase>
                            </InputAdornment>
                        }
                        aria-describedby="search-helper-text"
                        inputProps={{
                            'aria-label': 'weight'
                        }}
                    />
                </Grid>
            </Grid>
            <TableContainer elevation={2} component={Paper}>
                <Box sx={{ height: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10
                                    }
                                }
                            }}
                            pageSizeOptions={[5]}
                            // checkboxSelection
                            disableRowSelectionOnClick
                            isRowSelectable={false}
                            onRowClick={handleRowClick}
                            isCellEditable={false}
                        />
                    </ThemeProvider>
                </Box>
            </TableContainer>

            {/* <TableContainer elevation={2} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">FirstName</TableCell>
                            <TableCell align="left">Contact No</TableCell>
                            <TableCell align="right">Total Amount</TableCell>
                            <TableCell align="right">Order Date</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                            <TableRow
                                hover
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={() => {
                                    handleClickOpen();
                                    setSelectedRow(row);
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="left">{row.firstName}</TableCell>
                                <TableCell align="left">{row.phone}</TableCell>
                                <TableCell align="right">{row.orderAmount}</TableCell>
                                <TableCell align="right">{row.orderedDate.substring(0, 10)}</TableCell>
                                <TableCell align="center">{getStatusChip(row.status)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={orders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
            <Dialog fullScreen open={open} onClose={handleClickOpen} TransitionComponent={Transition}>
                <ViewOrderPage open={open} setOpen={setOpen} rowDetails={selectedRow} onEnd={getOrders} />
            </Dialog>
            <Dialog
                scroll="paper"
                fullWidth
                maxWidth="sm"
                open={openConfirm}
                onClose={handleClickConfirmClose}
                TransitionComponent={Transition}
            >
                <ConfirmationDialog
                    setOpen={setOpenConfirm}
                    Title="Are you sure ?"
                    Description="You are trying to delete a order. Are you want to delete this?"
                    onAgree={() => deleteOrders(deletingId)}
                />
            </Dialog>
        </MainCard>
    );
}
