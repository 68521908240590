import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { Helmet } from 'react-helmet';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Onet Admin Panel</title>
                <meta name="description" content="This is the admin platform for Onet Market" />
                <meta property="og:title" content="Onet Admin Panel" />
                <meta property="og:description" content="This is the admin platform for Onet Market" />
                <meta property="og:image" content="https://starfish-app-vil5p.ondigitalocean.app/uploads/1683451469381-19951228.jpg" />
                <meta property="og:url" content="https://admin.onetmarket.com" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://admin.onetmarket.com" />
            </Helmet>
            <StyledEngineProvider injectFirst>
                <ReactNotifications />
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
};

export default App;
