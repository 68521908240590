import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import { Box } from '@mui/system';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';

export default function CatogorySelector({ open, setOpen, setValues, data }) {
    const [step, setStep] = useState(1);
    const [title, setTitle] = useState('Select Category');
    const [subTitle, setSubTitle] = useState('');
    const [options, setOptions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [category, setCategory] = useState('');
    const [sCategory, setSCategory] = useState('');
    const [isFinish, setIsFinish] = useState(false);
    const scroll = 'paper';
    const stepsCount = 2;
    // const categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5', 'Category 6'];

    const handleClose = () => {
        setOpen(false);
        setStep(1);
        setCategory('');
        setSCategory('');
        setTitle('Select Category');
        setSubTitle('');
        setIsFinish(false);
    };

    const handleFinish = () => {
        setValues({ ...data, category, sCategory });
        setOpen(false);
        setIsFinish(false);
    };

    const handleNext = (element) => {
        console.log(element);

        switch (step) {
            case 1:
                setCategory(element);
                setTitle('Select Sub Category');
                setSubTitle(`${element} >`);
                setStep(step + 1);
                setIsFinish(false);
                getSubCategories(element);
                break;
            case 2:
                setSCategory(element);
                setTitle('Select Sub Category');
                setSubTitle(`${category} > ${element}`);
                setIsFinish(true);
                break;
            default:
                setStep(0);
                setIsFinish(false);
                setOptions(categories);
                break;
        }
    };

    const handleBack = () => {
        setIsFinish(false);
        switch (step) {
            case 2:
                setSCategory('');
                setTitle('Select Category');
                setSubTitle(`${category} > `);
                setOptions(categories);
                setStep(step - 1);
                break;
            default:
                setStep(1);
                setCategory('');
                setSCategory('');
                setTitle('Select Category');
                setOptions(categories);
                setSubTitle('');
                break;
        }
    };

    // const getHCategory = () => {
    //     HttpCommon.get('/api/hcategory')
    //         .then((res) => {
    //             setHCategory(res.data);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    function getHeadCategories() {
        HttpCommon.get('/api/category/getHeads')
            .then((res) => {
                console.log(res.data);
                const tempArr = [];
                if (res.data.success) {
                    res.data.data.map((element) => {
                        tempArr.push({ label: element.name, id: element.id });
                    });
                }
                console.log(tempArr);
                setCategories(tempArr);
                setOptions(tempArr);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getSubCategories(element) {
        HttpCommon.get(`/api/category/getSubs/${element}`)
            .then((res) => {
                console.log(res.data);
                const tempArr = [];
                if (res.data.success) {
                    res.data.data.map((element) => {
                        tempArr.push({ label: element.name, id: element.id });
                    });
                }
                console.log(tempArr);
                setSubCategories(tempArr);
                setOptions(tempArr);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        setTitle('Select Category');
        getHeadCategories();
    }, []);

    return (
        <>
            <DialogTitle id="scroll-dialog-title" fontSize={20}>
                {title}
            </DialogTitle>
            <DialogTitle id="scroll-dialog-title" marginTop={-4}>
                {subTitle}
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'} sx={{ paddingX: 10 }}>
                {options.map((element) => (
                    <Card
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: 40,
                            border: 'solid 1px #CDD5DF',
                            borderRadius: 2,
                            paddingX: 1,
                            paddingTop: 1.4,
                            marginBottom: 0.7,
                            '&:hover': {
                                borderColor: 'black'
                                // boxShadow: 2
                            }
                        }}
                        onClick={() => handleNext(element.label)}
                    >
                        {element.label}
                    </Card>
                ))}
            </DialogContent>
            <DialogActions>
                {step > 1 && step <= stepsCount ? (
                    <Button onClick={handleBack}>Back</Button>
                ) : (
                    <Button onClick={handleClose}>Cancel</Button>
                )}
                {isFinish ? <Button onClick={handleFinish}>Finish</Button> : <></>}
            </DialogActions>
        </>
    );
}
