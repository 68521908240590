// material-ui
import {
    AppBar,
    Button,
    Dialog,
    Divider,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Slide,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { shouldForwardProp } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import Dropzone from 'react-dropzone-uploader';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CatogorySelector from 'ui-component/category-selector/CategorySelector';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';

// ==============================|| Edit Categories PAGE ||============================== //

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledButton = styled(Button, { shouldForwardProp })(({ theme }) => ({
    // cursor: 'pointer',
    // padding: 16,
    '&:hover': {
        // background: theme.palette.primary
        backgroundColor: '#fff',
        color: '#3c52b2'
    }
}));

// receives array of files that are done uploading when submit button is clicked
const handleSubmit = (files) => {
    console.log(files.map((f) => f.meta));
};

export default function EditCategoriesPage({ open, setOpen, rowDetails, onEnd }) {
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [values, setValues] = useState({
        id: '',
        name: '',
        head: ''
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickClose = () => {
        setCategoryOpen(false);
    };

    const handleSubmit = () => {
        const data = {
            id: values.id,
            name: values.name
        };

        if (values.head !== undefined && values.head !== '') {
            data.head = values.head;
        }
        console.log(data);
        HttpCommon.put(`/api/category/`, data)
            .then((response) => {
                console.log(response.data);
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Uploaded!', msg: 'Categories Updates Succesfully', type: 'success' });
                } else {
                    messages.addMessage({ title: 'Failed!', msg: response.data.message, type: 'danger' });
                }
                onEnd();
            })
            .catch((err) => {
                console.log(err);
                messages.addMessage({ title: 'Failed!', msg: 'Categories Update Failed', type: 'danger' });
            });
        setOpen(false);
    };

    useEffect(() => {
        setValues({
            id: rowDetails.id,
            name: rowDetails.name,
            head: rowDetails.head
        });
    }, []);

    return (
        <>
            <AppBar sx={{ position: 'relative' }} color="secondary">
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="subtitle1" component="div">
                        Edit Categories
                    </Typography>
                    <StyledButton color="inherit" onClick={handleSubmit}>
                        save
                    </StyledButton>
                </Toolbar>
            </AppBar>
            <Grid container justifyContent="center" alignItems="center" padding={3} spacing={2}>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Id" value={values.id} onChange={handleChange('id')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <TextField id="outlined-error" fullWidth label="Name" value={values.name} onChange={handleChange('name')} />
                </Grid>
                {values.head !== undefined && values.head !== '' && (
                    <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                        <TextField id="outlined-error" fullWidth label="Head" value={values.head} onChange={handleChange('head')} />
                    </Grid>
                )}
            </Grid>
        </>
    );
}
