// material-ui
import { Autocomplete, createFilterOptions, Button, Grid, Slide, TextField, Switch, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import 'animate.css/animate.min.css';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import { useState, useEffect, forwardRef } from 'react';
import CatogorySelector from 'ui-component/category-selector/CategorySelector';
import { styled } from '@mui/material/styles';
// ==============================|| Add Categories PAGE ||============================== //

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
};

export default function AddCategoriesPage({ open, setOpen, onEnd }) {
    const [values, setValues] = useState({
        id: '',
        name: '',
        head: ''
    });
    const [categories, setCategories] = useState([]);
    const [isSub, setIsSub] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleChange = (prop) => (event) => {
        console.log(event);
        console.log(event.target);
        console.log(event.target.value);
        setValues({ ...values, [prop]: event.target.value });
    };
    // specify upload params and url for your files
    const getUploadParams = ({ meta }) => {
        return { url: ' ' };
    };

    // called every time a file's `status` changes
    const handleChangeStatus = (file, status) => {
        console.log('status, meta, file');
        console.log(status, file);
        const tempArr = values.img;
        console.log(tempArr);
        console.log(values.img);
        if (status === 'ready') {
            tempArr.push(file);
            setValues({ ...values, img: tempArr });
        } else if (status === 'removed') {
            const fileIndex = tempArr.indexOf(file); // get  "file" index
            // remove file from the img array
            tempArr.splice(fileIndex, 1);
            setValues({ ...values, img: tempArr });
        }
    };

    function getHeadCategories() {
        HttpCommon.get('/api/category/getHeads')
            .then((res) => {
                console.log(res.data);
                const tempArr = [];
                if (res.data.success) {
                    res.data.data.map((element) => {
                        tempArr.push({ label: element.name, id: element.id });
                    });
                }
                console.log(tempArr);
                setCategories(tempArr);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = () => {
        const data = {
            id: values.id,
            name: values.name
        };
        console.log(isSub);
        if (isSub) {
            console.log(values.head);
            if (values.head !== undefined && values.head !== '') {
                data.head = values.head;
            } else {
                messages.addMessage({ title: 'Failed!', msg: 'Select a head category', type: 'danger' });
                return 0;
            }
        }
        console.log(data);
        HttpCommon.post(`/api/category/`, data)
            .then((response) => {
                console.log(response.data);
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Uploaded!', msg: 'Categories Created Succesfully', type: 'success' });
                } else {
                    messages.addMessage({ title: 'Failed!', msg: response.data.message, type: 'danger' });
                }
                onEnd();
            })
            .catch((err) => {
                console.log(err);
                messages.addMessage({ title: 'Failed!', msg: 'Categories Creation Failed', type: 'danger' });
            });
        setOpen(false);
    };

    useEffect(() => {
        getHeadCategories();
    }, []);

    return (
        <MainCard
            title={isSub ? 'Add Sub Categories' : 'Add Categories'}
            secondary={
                <FormControlLabel
                    control={
                        <Switch
                            checked={isSub}
                            color="secondary"
                            onChange={(event) => setIsSub(event.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label="Sub Category"
                />
            }
        >
            <Grid container justifyContent="center" alignItems="center" mt={0} spacing={2}>
                {isSub && (
                    <Grid item justifyContent="center" alignItems="center" xs={12} md={12}>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            filterOptions={filterOptions}
                            id="combo-box-demo"
                            options={categories}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setValues({ ...values, head: newValue.label });
                            }}
                            renderInput={(params) => <TextField {...params} label="Head" />}
                        />
                    </Grid>
                )}
                <Grid item justifyContent="center" alignItems="center" xs={12} md={12}>
                    <TextField id="outlined-error" fullWidth label="Id" onChange={handleChange('id')} />
                </Grid>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={12}>
                    <TextField id="outlined-error" fullWidth label="Title" onChange={handleChange('name')} />
                </Grid>

                <Grid item justifyContent="center" alignItems="center" xs={12}>
                    <Box sx={{ mt: 2 }}>
                        <AnimateButton>
                            <Button fullWidth size="large" type="submit" variant="contained" color="secondary" onClick={handleSubmit}>
                                Add Categories
                            </Button>
                        </AnimateButton>
                    </Box>
                </Grid>
            </Grid>
            {/* <Grid container justifyContent="center" alignItems="center" mt={2} xs={12} lg={6} spacing={2}>
                <Grid item justifyContent="center" alignItems="center" xs={12} md={6}>
                    <Typography align="center"> Categories Name </Typography>
                </Grid>
                <Grid item justifyContent="flex-end" xs={12} md={6}>
                    <TextField align="center" fullWidth id="outlined-error" label="Name" />
                </Grid>
            </Grid> */}
        </MainCard>
    );
}
